import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { LogoWrapper } from "./style";
import LogoSvg from "../../assets/images/logo/junghans-logo.inline.svg";

const Logo = ({ className, variant, ...restProps }) => {
    return (
        <LogoWrapper className={className} $variant={variant} {...restProps}>
            <Link to="/">
                <LogoSvg />
            </Link>
        </LogoWrapper>
    );
};

Logo.propTypes = {
    className: PropTypes.string,
    variant: PropTypes.oneOf(["white", "dark"]),
};

Logo.defaultProps = {
    justifyContent: "flex-start",
    variant: "dark",
};

export default Logo;
