import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Container, Row, Col } from "@ui/wrapper";
import Text from "@ui/text";
import Anchor from "@ui/anchor";
import Heading from "@ui/heading";
import Social, { SocialLink } from "@ui/social";
import { SocialType } from "@utils/types";
import {
    FooterWrap,
    FooterTop,
    FooterWidget,
    LogoWidget,
    TextWidget,
    FooterWidgetList,
    FooterBottom,
} from "./style";
import LogoSvg from "../../../assets/images/logo/junghans-logo.inline.svg";
import {IconWrap} from "../../../components/box-icon/layout-03/style";
import Image from "../../../components/ui/image";
import {InfoIcon} from "../../header/layout-04/style";
const Footer = ({ data }) => {
    return (
        <FooterWrap>
            <FooterTop>
                <Container>
                    <Row>
                        <Col lg={4} sm={6}>
                            <FooterWidget mb={["30px", null, null, 0]}>
                                <LogoWidget>
                                    <LogoSvg />
                                </LogoWidget>
                                {data?.contact && (
                                    <TextWidget>
                                        {data.contact?.address && (
                                            <Text mb="10px" ml="4px">
                                                {data.contact.street}
                                                <br />
                                                {data.contact.city}
                                                <br />
                                                <br />
                                                <i className="fa fa-at"></i>
                                                &nbsp;
                                                <Anchor
                                                    path={`mailto:${data.contact.email}`}
                                                    color="text"
                                                    $hover={{ layout: 2 }}
                                                >
                                                    {data.contact.email}
                                                </Anchor>
                                                <br />
                                                <i className="fa fa-phone"></i>
                                                &nbsp;
                                                <Anchor
                                                    path={`tel:${data.contact.tel}`}
                                                    color="heading"
                                                    $hover={{ layout: 2 }}
                                                >
                                                    {data.contact.tel}
                                                </Anchor>
                                            </Text>
                                        )}
                                    </TextWidget>
                                )}
                            </FooterWidget>
                        </Col>
                        <Col lg={2} md={4} sm={6}>
                            <FooterWidget>
                                <Heading as="h6" mt="-3px" mb="20px">
                                    &nbsp;
                                </Heading>
                                <FooterWidgetList>
                                    <li>
                                        <Anchor
                                            path="/impressum"
                                            color="text"
                                            $hover={{ layout: 2 }}
                                        >
                                            Impressum
                                        </Anchor>
                                    </li>
                                    <li>
                                        <Anchor
                                            path="/datenschutz"
                                            color="text"
                                            $hover={{ layout: 2 }}
                                        >
                                            Datenschutzerklärung
                                        </Anchor>
                                    </li>
                                </FooterWidgetList>
                            </FooterWidget>
                        </Col>
                    </Row>
                </Container>
            </FooterTop>
            <FooterBottom>
                <Container>
                    <Row alignItems="center">
                        <Col md={6} textAlign={["center", null, "left"]}>
                            {data?.copyright && (
                                <Text pb={["15px", 0]}>
                                    &copy; {new Date().getFullYear()}{" "}
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: data.copyright,
                                        }}
                                    />
                                </Text>
                            )}
                        </Col>
                        {/*<Col md={6} textAlign={["center", null, "right"]}>*/}
                        {/*    {data?.socials && (*/}
                        {/*        <Social*/}
                        {/*            space="16px"*/}
                        {/*            tooltip={true}*/}
                        {/*            shape="rounded"*/}
                        {/*            variant="outlined"*/}
                        {/*        >*/}
                        {/*            {data.socials.map((social) => (*/}
                        {/*                <SocialLink*/}
                        {/*                    key={social.id}*/}
                        {/*                    path={social.link}*/}
                        {/*                    title={social.title}*/}
                        {/*                >*/}
                        {/*                    <i*/}
                        {/*                        className={cn(*/}
                        {/*                            social.icon,*/}
                        {/*                            "link-icon"*/}
                        {/*                        )}*/}
                        {/*                    ></i>*/}
                        {/*                </SocialLink>*/}
                        {/*            ))}*/}
                        {/*        </Social>*/}
                        {/*    )}*/}
                        {/*</Col>*/}
                    </Row>
                </Container>
            </FooterBottom>
        </FooterWrap>
    );
};

Footer.propTypes = {
    data: PropTypes.shape({
        socials: PropTypes.arrayOf(PropTypes.shape(SocialType)),
        copyright: PropTypes.string,
        contact: PropTypes.shape({
            address: PropTypes.string,
            street: PropTypes.string,
            city: PropTypes.string,
            tel: PropTypes.string,
            email: PropTypes.string,
        }),
    }),
};

export default Footer;
